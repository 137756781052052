<template>
  <header-layout
    title = "Pagina niet gevonden"
    subtitle = "Foutcode 404"
    :displayBreadcrumb = false
  />

  <section>
    <div class="container">
      <single-block>
        <error-alert>
          De server kan de opgevraagde pagina niet vinden.
        </error-alert>
      </single-block>
    </div>
  </section>

  <footer-layout />
</template>

<script>
  import HeaderLayout from "@/components/layouts/Header.vue"
  import FooterLayout from "@/components/layouts/Footer.vue"
  import SingleBlock from "@/components/blocks/Single.vue"
  import ErrorAlert from "@/components/alerts/Error.vue"

  export default {
    name: "ErrorPageNotFoundView",
    components: {
      HeaderLayout,
      FooterLayout,
      SingleBlock,
      ErrorAlert,
    }
  }
</script>
