
import ImageLoader from "@/assets/scripts/ImageLoader";
import HeaderLayout from "@/components/layouts/Header.vue";
import FooterLayout from "@/components/layouts/Footer.vue";
import TripleBlock from "@/components/blocks/Triple.vue";

export default {
  name: "ServicebeurtGrootView",
  components: {
    HeaderLayout,
    FooterLayout,
    TripleBlock,
  },
  data() {
    return {
      serviceImage: ImageLoader.loadImageSrc("assets/images/decorators/service.jpg"),
    }
  },
}
