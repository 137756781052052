import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b3e751d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.path == $data.currentRoute.path)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString($props.label), 1))
      : (_openBlock(), _createElementBlock("a", {
          key: 1,
          href: $props.path
        }, _toDisplayString($props.label), 9, _hoisted_2)),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ], 64))
}