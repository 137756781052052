
import ImageLoader from "@/assets/scripts/ImageLoader";
import TripleBlock from "@/components/blocks/Triple.vue";
import ContactDetailsTable from "@/components/tables/ContactDetails.vue";
import OpeningHoursTable from "@/components/tables/OpeningHours.vue";

export default {
  name: "FooterLayout",
  props: {
    displayContactDetails: {
      type: Boolean,
      default: true,
    },
    displayOpeningHours: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    TripleBlock,
    ContactDetailsTable,
    OpeningHoursTable,
  },
  data() {
    return {
      currentYear: 0 as number,
      facebookLogo: ImageLoader.loadImageSrc("assets/images/logos/facebook.png") as string,
      facebookLogoActive: ImageLoader.loadImageSrc("assets/images/logos/facebook_active.png") as string,
      linkedinLogo: ImageLoader.loadImageSrc("assets/images/logos/linkedin.png") as string,
      linkedinLogoActive: ImageLoader.loadImageSrc("assets/images/logos/linkedin_active.png") as string,
    }
  },
  methods: {
    setCurrentYear(): void {
      (this as any).currentYear = new Date().getFullYear();
    },
  },
  mounted() {
    (this as any).setCurrentYear();
  },
}
