
    import ColorConverter from "@/assets/scripts/ColorConverter"

    export default {
        name: "ErrorAlert",
        methods: {
            setBackGroundColor(): void {
                const opacity = 0.25;
                const hexadecimal = getComputedStyle(document.body).getPropertyValue("--red-color");
                (this as any).$refs.alertContent.style.backgroundColor = ColorConverter.hexToRgba(hexadecimal, opacity);
            },
        },
        mounted() {
            (this as any).setBackGroundColor();
        },
    }
