import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9345047e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_layout = _resolveComponent("header-layout")!
  const _component_notice_alert = _resolveComponent("notice-alert")!
  const _component_double_block = _resolveComponent("double-block")!
  const _component_PageButton = _resolveComponent("PageButton")!
  const _component_quadruple_block = _resolveComponent("quadruple-block")!
  const _component_footer_layout = _resolveComponent("footer-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_header_layout, { title: "Seizoenscheck" }),
    _createElementVNode("section", null, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_double_block, null, {
          default: _withCtx(() => [
            _createVNode(_component_notice_alert, null, {
              default: _withCtx(() => [
                _createTextVNode(" Combineer een zomer- of wintercheck met een onderhoudsbeurt voor uw airco: ’s Zomers houdt u het hoofd heerlijk koel en 's winters beslaan uw ruiten niet. ")
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_quadruple_block, null, {
          default: _withCtx(() => [
            _createVNode(_component_PageButton, {
              title: "Zomercheck",
              path: "seizoenscheck/zomer"
            })
          ]),
          _: 1
        }),
        _createVNode(_component_quadruple_block, null, {
          default: _withCtx(() => [
            _createVNode(_component_PageButton, {
              title: "Wintercheck",
              path: "seizoenscheck/winter"
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_footer_layout)
  ], 64))
}