
import { RouteRecord } from "vue-router";
import RouteExtractor from "@/assets/scripts/RouteExtractor";

export default {
  name: "SitemapNode",
  props: {
    route: {
      type: Object,
      required: true,
    },
    routes: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getChildRoutes(): RouteRecord[] {
      return RouteExtractor.getChildRoutes(
        (this as any).route, (this as any).routes);
    },
  },
}
