
    export default {
        name: "PageButton",
        props: {
            title: {
                type: String,
                required: true,
            },
            path: {
                type: String,
                required: true,
            },
        },
    }
