
import ImageLoader from "@/assets/scripts/ImageLoader";
import HeaderLayout from "@/components/layouts/Header.vue";
import FooterLayout from "@/components/layouts/Footer.vue";
import SingleBlock from "@/components/blocks/Single.vue";
import DoubleBlock from "@/components/blocks/Double.vue";
import NoticeAlert from "@/components/alerts/Notice.vue";

export default {
  name: "WerkzaamhedenView",
  components: {
    HeaderLayout,
    FooterLayout,
    SingleBlock,
    DoubleBlock,
    NoticeAlert,
  },
  data() {
    return { 
      activitiesImage: ImageLoader.loadImageSrc("assets/images/decorators/activities.jpg")
    }
  },
}
