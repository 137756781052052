
import HeaderLayout from "@/components/layouts/Header.vue";
import FooterLayout from "@/components/layouts/Footer.vue";
import DoubleBlock from "@/components/blocks/Double.vue";
import QuadrupleBlock from "@/components/blocks/Quadruple.vue";
import NoticeAlert from "@/components/alerts/Notice.vue";
import PageButton from "@/components/buttons/Page.vue";

export default {
  name: "SeizoenscheckView",
  components: {
    HeaderLayout,
    FooterLayout,
    DoubleBlock,
    QuadrupleBlock,
    NoticeAlert,
    PageButton,
  },
}
