
import ImageLoader from "@/assets/scripts/ImageLoader";
import HeaderLayout from "@/components/layouts/Header.vue";
import FooterLayout from "@/components/layouts/Footer.vue";
import DoubleBlock from "@/components/blocks/Double.vue";

export default {
  name: "SeizoenscheckZomerView",
  components: {
    HeaderLayout,
    FooterLayout,
    DoubleBlock,
  },
  data() {
    return {
      coolantChangeImage: ImageLoader.loadImageSrc("assets/images/decorators/coolant_change.jpg"),
    }
  },
}
