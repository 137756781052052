
import ImageLoader from "@/assets/scripts/ImageLoader";
import HeaderLayout from "@/components/layouts/Header.vue";
import FooterLayout from "@/components/layouts/Footer.vue";
import QuadrupleBlock from "@/components/blocks/Quadruple.vue";

export default {
  name: "ApkView",
  components: {
    HeaderLayout,
    FooterLayout,
    QuadrupleBlock,
  },
  data() {
    return {
      inspectionImage: ImageLoader.loadImageSrc("assets/images/decorators/inspection.png"),
    }
  },
}
