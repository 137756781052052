
    export default {
        name: "LogoButton",
        props: {
            isBig: {
                type: Boolean,
                default: false,
            },
        },
    }
