
import ImageLoader from "@/assets/scripts/ImageLoader";
import HeaderLayout from "@/components/layouts/Header.vue";
import BannerLayout from "@/components/layouts/Banner.vue";
import FooterLayout from "@/components/layouts/Footer.vue";
import SingleBlock from "@/components/blocks/Single.vue";
import DoubleBlock from "@/components/blocks/Double.vue";
import QuadrupleBlock from "@/components/blocks/Quadruple.vue";
import NoticeAlert from "@/components/alerts/Notice.vue";

export default {
  name: "IndexView",
  components: {
    HeaderLayout,
    BannerLayout,
    FooterLayout,
    SingleBlock,
    QuadrupleBlock,
    NoticeAlert,
  },
  data() {
    return {
      thumbImage: ImageLoader.loadImageSrc("assets/images/decorators/thumb.png"),
      shopImage: ImageLoader.loadImageSrc("assets/images/decorators/shop.jpg"),
    }
  },
}
