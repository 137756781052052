import { Router, RouteRecordRaw, createRouter, createWebHistory } from "vue-router";
import Index from "@/views/Index.vue";
import Werkzaamheden from "@/views/werkzaamheden/Werkzaamheden.vue";
import Apk from "@/views/apk/Apk.vue";
import Servicebeurt from "@/views/servicebeurt/Servicebeurt.vue";
import ServicebeurtKlein from "@/views/servicebeurt/klein/Klein.vue";
import ServicebeurtGroot from "@/views/servicebeurt/groot/Groot.vue";
import Seizoenscheck from "@/views/seizoenscheck/Seizoenscheck.vue";
import SeizoenscheckZomer from "@/views/seizoenscheck/zomer/Zomer.vue";
import SeizoenscheckWinter from "@/views/seizoenscheck/winter/Winter.vue";
import Contact from "@/views/contact/Contact.vue";
import Sitemap from "@/views/sitemap/Sitemap.vue";
import PageNotFound from "@/views/errors/PageNotFound.vue";

const routes: RouteRecordRaw[] = [
    {
        path: "/",
        name: "AutobedrijfPatrickDon",
        component: Index,
        meta: {
            title: "Autobedrijf Patrick Don",
        },
    },
    {
        path: "/werkzaamheden",
        name: "Werkzaamheden",
        component: Werkzaamheden,
        meta: {
            title: "Werkzaamheden",
        },
    },
    {
        path: "/apk",
        name: "Apk",
        component: Apk,
        meta: {
            title: "Apk",
        },
    },
    {
        path: "/servicebeurt",
        name: "Servicebeurt",
        component: Servicebeurt,
        meta: {
            title: "Servicebeurt",
        },
    },
    {
        path: "/servicebeurt/klein",
        name: "ServicebeurtKlein",
        component: ServicebeurtKlein,
        meta: {
            title: "Kleine servicebeurt",
        },
    },
    {
        path: "/servicebeurt/groot",
        name: "ServicebeurtGroot",
        component: ServicebeurtGroot,
        meta: {
            title: "Grote servicebeurt",
        },
    },
    {
        path: "/seizoenscheck",
        name: "Seizoenscheck",
        component: Seizoenscheck,
        meta: {
            title: "Seizoenscheck",
        },
    },
    {
        path: "/seizoenscheck/zomer",
        name: "SeizoenscheckZomer",
        component: SeizoenscheckZomer,
        meta: {
            title: "Zomercheck",
        },
    },
    {
        path: "/seizoenscheck/winter",
        name: "SeizoenscheckWinter",
        component: SeizoenscheckWinter,
        meta: {
            title: "Wintercheck",
        },
    },
    {
        path: "/contact",
        name: "Contact",
        component: Contact,
        meta: {
            title: "Contact",
        },
    },
    {
        path: "/sitemap",
        name: "Sitemap",
        component: Sitemap,
        meta: {
            title: "Sitemap",
            hideInNavigator: true,
            hideInSitemap: true,
        },
    },
    {
        path: "/:catchAll(.*)",
        name: "PageNotFound",
        component: PageNotFound,
        meta: {
            title: "Pagina niet gevonden",
            hideInNavigator: true,
            hideInSitemap: true,
        },
    },
];

const router: Router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.afterEach((to) => {
    if (to.meta.title == null) {
        document.title = "Autobedrijf Patrick Don | De service van vroeger, de kennis van nu";
    } else {
        let appendix: string;

        if (to.fullPath == "/") {
            appendix = " | De service van vroeger, de kennis van nu";
        } else {
            appendix = " | Autobedrijf Patrick Don";
        }

        document.title = to.meta.title as string + appendix;
    }
});

export default router;
